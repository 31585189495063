import "./App.scss";
import { useState } from "react";
import Results from "./pages/Results/results";
import Header from "./components/Header/header";

function App() {
  const [searchResults, setSearchResults] = useState();

  return (
    <>
      <Header setResults={setSearchResults} />
      <div className="container">
        <Results results={searchResults} />
      </div>
    </>
  );
}

export default App;
