import "./header.scss";
import Logo from "../../assets/groupby-logo2.svg";
import Expand from "../../assets/expand-icon.jpeg";
import Collapse from "../../assets/collapse-icon.jpeg";
import axios from "axios";
import { useState } from "react";
import Refinements from "../../components/Refinements/refinements";

export default function Header(props) {
  const [searchResults, setSearchResults] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  var searchUrl =
    "https://demo-s4r-apparel-dot-presales-demos-330114.uc.r.appspot.com/search-api";
  var area = "Apparel";
  var pageSize = 20;
  var pageQuery = null;

  function urlDecode(url) {
    if (url) {
      if (url.indexOf("%") !== -1) {
        url = decodeURIComponent(url);
      }
      url = url.replace(/\+/g, " ");
      url = url.replace(/\*/g, "");
      url = url.replace(/~/g, ".");
      url = url.replace(/PLUS/g, "+");
      url = url.replace(/SLASH/g, "/");
      url = url.replace(/PERCENT/g, "%");
    }
    return url;
  }

  function showActivity(pkg) {
    if(!document.querySelector('.monitor').classList.contains('showing')) {
      document.querySelector('.monitor').classList.add('showing');
    }
    document.querySelector('.activity').innerHTML += pkg;
    setTimeout(function() {
      document.querySelector('.activity').scrollTop = document.querySelector('.activity').scrollHeight;
    }, 200);
  }

  function doSearch(q, refs, currentRef, refAdded) {
    var codeId = new Date();
    pageQuery = q;
    let args = {
      query: urlDecode(decodeURIComponent(q)),
      collection: "clothing",
      area: area,
      pageSize: pageSize,
      fields: "*",
      dynamicFacet: true,
      variantRollupKeys: ["colorFamilies", "colors", "sizes"],
      skip: 0,
      refinements: refs != null ? refs : null,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "skip-cache": "false",
      },
    };
    if(!refs) {
      let code = 'let args = <pre>' + JSON.stringify(args, null, 2) + ';</pre><br /><br />';
      code += 'let axiosConfig = <pre>' + JSON.stringify(axiosConfig,null,2) + ';</pre><br /><br />';
      code += 'axios<br />&nbsp;.post(searchUrl, args, axiosConfig)<br />&nbsp;.then((res) => {<br />&nbsp;&nbsp;// map "records" to product tiles using <a href="javascript:;" onclick="showData(this);" class="data-' + codeId.getTime() + '">res.data</a><br />&nbsp;})<br />&nbsp;.catch("ERROR");<br />};<br /><br />';
      showActivity('<div>searching query term <strong>' + q + '</strong><div class="code-holder" onclick="let ele=this;setTimeout(function(ele) {window.toggleActivity(ele);},100,ele)"><div class="code-title">&gt; CODE &lt;</div><div class="activity-code"><br />' + code + '</div></div></div>');
    }
    else {
      let code = 'let args = <pre>' + JSON.stringify(args, null, 2) + ';</pre><br /><br />';
      code += 'let axiosConfig = <pre>' + JSON.stringify(axiosConfig,null,2) + ';</pre><br /><br />';
      code += 'axios<br />&nbsp;.post(searchUrl, args, axiosConfig)<br />&nbsp;.then((res) => {<br />&nbsp;&nbsp;// map "records" to product tiles using <a href="javascript:;" onclick="showData(this);" class="data-' + codeId.getTime() + '">res.data</a><br />&nbsp;})<br />&nbsp;.catch("ERROR");<br />};<br /><br />';
      let refterm = 'refining ';
      if(!refAdded) {
        refterm = 'unrefining ';
      }
      showActivity('<div>' + refterm + '<strong>' + currentRef.parentElement.parentElement.parentElement.querySelector('.refinements-list__ul-item').id + '</strong> by <strong>' + currentRef.id.split("+")[1] + '</strong><div class="code-holder" onclick="let ele=this;setTimeout(function(ele) {window.toggleActivity(ele);},100,ele)"><div class="code-title">&gt; CODE &lt;</div><div class="activity-code"><br />' + code + '</div></div></div>');
    }
    axios
      .post(searchUrl, args, axiosConfig)
      .then((res) => {
        let activityData = document.querySelector('.data-' + codeId.getTime());
        if(activityData) {
          activityData.setAttribute('data-results', encodeURIComponent(JSON.stringify(res.data,null,2)));
          console.log('res.data',res.data);
        }
        setSearchResults(res.data);
        props.setResults(res.data);
      })
      .catch("ERROR");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    doSearch(searchTerm, null);
  };

  const handleActivity = (event) => {
    window.toggleActivity(event.target);
  };

  return (
    <>
      <div className="header">
        <div className="header-container">
          <div className="header-left">
            <a href="/">
              <img className="header-logo" src={Logo} alt="GroupBy Inc Logo" />
            </a>
          </div>
          <div className="header-right">
            <form
              className="header-form"
              id="headersearch"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                className="header-search"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Search for a product..."
              />
            </form>
            <div className="monitor">
              <div className="monitor-heading">ACTIVITY<img onClick={handleActivity} className="expand-icon" src={Expand} alt="Expand" /><img onClick={handleActivity} className="collapse-icon" src={Collapse} alt="Collapse" /></div>
              <div className="activity"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="refinements">
        <Refinements refinements={searchResults} search={doSearch} />
      </div>
    </>
  );
}
