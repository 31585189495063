import { isContentEditable } from "@testing-library/user-event/dist/utils";
import { useState } from "react";
import "./refinements.scss";

export default function Refinements(props) {
  const [showSub, setShowSub] = useState(null);

  const toggle = (i) => {
    if (showSub === i) {
      return setShowSub(null);
    }
    setShowSub(i);
  };

  const handleClick = (e) => {
    let refs = [];
    let ogReq = props.refinements.originalRequest;
    if (ogReq.refinements != null) {
      ogReq.refinements.map((ref) => {
        refs.push(ref);
      });
    }
    let toPush = {};
    if(e.target.id.split("+").length > 2) {
      toPush = {
        navigationName: e.target.id.split("+")[0],

        high: e.target.id.split("+")[2] === "undefined"
        ? Number.MAX_SAFE_INTEGER
        : e.target.id.split("+")[2],

        low: e.target.id.split("+")[1],
        type: "Range",
        displayName: "test",
        or: true,
      }
    } else {
      toPush = {
        navigationName: e.target.id.split("+")[0],
        type: "Value",
        displayName: "test",
        or: true,
        value: e.target.id.split("+")[1],
      }
    }
;
    if (e.target.checked === true) {
      refs.push(toPush);
      props.search(ogReq.query, refs, e.target, true);
    } else if (e.target.checked === false) {
      let refnum = -1;
      for(let i = 0; i < refs.length; i ++) {
        if(refs[i].navigationName == e.target.id.split("+")[0] && refs[i].value == e.target.id.split("+")[1]) {
          refs.splice(i,1);
          break;
        }
      }
      props.search(ogReq.query, refs, e.target, false);
    }
  };
  const refinements = props.refinements;
  if (typeof refinements !== "undefined") {
    const target = refinements.availableNavigation;
    const selectedRef = refinements.originalRequest.refinements;
    return (
      <div className="refinements-container">
        {target.map((item, i, key) => {
          const sub = item.refinements;
          return (
            <div className="refinements-list">
              <ul className="refinements-list__ul">
                <li className="refinements-list__ul-item" id={item.displayName} key={key}>
                  {item.displayName
                    .replace(/([A-Z,-])/g, " $1")
                    .trim()
                    .toUpperCase()}
                </li>
                <button
                  className="refinements-expand"
                  onClick={() => toggle(item.displayName)}
                >
                  {showSub === item.displayName
                  ? "-"
                  : "+"}
                </button>
              </ul>
              {sub.map((subitem, index) => {
                let isSelected = false;
                if(selectedRef != null) {
                  for(const ref of selectedRef) {
                    if(ref.navigationName === item.name && subitem.value === ref.value) {
                        isSelected = true;
                    }
                  }
                }
                if (subitem.type === "Range") {
                  if(item.name === "price") {
                    return (
                      <div
                        className={
                          showSub === item.displayName
                            ? "refinements-sub-show"
                            : "refinements-sub"
                        }
                      >
                        <form className="refinements-sub__list">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            id={item.name + "+" + subitem.low + "+" + subitem.high}
                            onChange={handleClick}
                            className="checkboxes"
                          />
                          <label
                            htmlFor={subitem.value}
                            className="refinements-sub__list-item"
                          >
                            {subitem.low === 0
                            ? "Below "
                            : "$" + subitem.low}

                            {isNaN(subitem.high) || subitem.low === 0
                            ? ""
                            : "-"}

                            {isNaN(subitem.high)
                            ? "& Above"
                            :  "$" + subitem.high}

                            ({subitem.count})
                          </label>
                        </form>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={
                          showSub === item.displayName
                            ? "refinements-sub-show"
                            : "refinements-sub"
                        }
                      >
                        <form className="refinements-sub__list">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            id={item.name + "+" + subitem.low + "+" + subitem.high}
                            onChange={handleClick}
                            className="checkboxes"
                          />
                          <label
                            htmlFor={subitem.value}
                            className="refinements-sub__list-item"
                          >
                            {subitem.low} - {subitem.high}
                          </label>
                        </form>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      className={
                        showSub === item.displayName
                          ? "refinements-sub-show"
                          : "refinements-sub"
                      }
                    >
                      <form className="refinements-sub__list">
                        <input
                          type="checkbox"
                          checked={isSelected}
                          id={item.name + "+" + subitem.value}
                          onChange={handleClick}
                          className="checkboxes"
                        />
                        <label
                          htmlFor={subitem.value}
                          className="refinements-sub__list-item"
                        >
                          {subitem.value} ({subitem.count})
                        </label>
                      </form>
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
