import "./results.scss";
import Left from "../../assets/arrow-left.png";
import Right from "../../assets/arrow-right.png";
import { useRef } from "react";

export default function Results(props) {
  const propResults = props.results;
  function changeImage(prodId, newImg) {
    if (newImg != null && prodId != null) {
      if (document.getElementById(prodId) != null) {
        document.getElementById(prodId).src = newImg;
      }
    }
  }

  const carouselId = useRef({});

  function carousel(id) {
    if (!carouselId.current[id]) {
      carouselId.current[id] = 0;
    }

    const prev = document.querySelector(".carousel__button--left");
    const next = document.querySelector(".carousel__button--right");
    const track = document.getElementById("swatch-" + id);
    const carouselWidth = document.getElementById(
      "carousel-inner-" + id
    ).offsetWidth;

    track.style.transform = `translateX(-${
      carouselId.current[id] * carouselWidth
    }px)`;

    if (carouselId.current[id] === 0) {
      carouselId.current[id]++;
      prev.classList.add("hide");
      next.classList.remove("hide");
      prev.classList.remove("show");
    } else {
      carouselId.current[id]--;
      prev.classList.remove("hide");
      prev.classList.add("show");
      next.classList.add("hide");
    }
  }

  if (!propResults) {
    return (
      <div className="noresults">
        <p className="noresults-text">Search for something!</p>
      </div>
    );
  } else if (typeof propResults !== "undefined") {
    const results = propResults.records;
    return (
      <>
        <div className="result">
          <div className="count">
            <p>
              {propResults.totalRecordCount} Results for "{propResults.query}"
            </p>
          </div>
          <div className="card-container">
            {results.map((item, key) => {
              let brand = "";
              let rating = 0.0;
              const product = item.allMeta;
              // console.log(product);
              if (
                typeof product !== "undefined" &&
                typeof product.variants !== "undefined"
              ) {
                if (typeof product.variants[0].images !== "undefined") {
                  if (typeof product.brands !== "undefined") {
                    brand = product.brands[0];
                  }
                  if (typeof product.rating !== "undefined") {
                    rating = product.rating.averageRating;
                  }
                  let variants = !product.attributes
                    ? []
                    : !product.attributes.swatchImage
                    ? []
                    : !product.attributes.swatchImage.text
                    ? []
                    : product.attributes.swatchImage.text;
                  let prodId = product.id;
                  return (
                    <div className="result-card">
                      <div className="result-card__top">
                        <img
                          id={prodId}
                          src={
                            product.variants &&
                            product.variants[0] &&
                            product.variants[0].images &&
                            product.variants[0].images[0] &&
                            product.variants[0].images[0].uri
                              ? product.variants[0].images[0].uri
                              : product.attributes &&
                                product.attributes.productImage &&
                                product.attributes.productImage.text &&
                                product.attributes.productImage.text.length > 0
                              ? product.attributes.productImage.text[0]
                              : "https://storage.googleapis.com/groupby-demo-images/image-not-found.png"
                          }
                          alt="pic"
                          className="result-card__image"
                        />
                      </div>
                      <div className="result-card__bottom">
                        <div className="result-card__swatch">
                          <div
                            className="carousel-container"
                            id={"carousel-" + prodId}
                          >
                            <div
                              className="carousel-inner"
                              id={"carousel-inner-" + prodId}
                            >
                              <div className="track" id={"swatch-" + prodId}>
                                {variants.map((swatch, index) => {
                                  if (variants.length > 1) {
                                    return (
                                      <div
                                        className="result-card__swatchImg"
                                        id={index}
                                        onClick={function () {
                                          changeImage(
                                            prodId,
                                            product.attributes.productImage
                                              .text[index]
                                          );
                                        }}
                                      >
                                        <img src={swatch} alt={"img"} />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                            {variants.length > 5 ? (
                              <div className="carousel-nav">
                                <img
                                  className="carousel__button--left hide"
                                  id="carousel__button--left"
                                  src={Left}
                                  alt={"img"}
                                  onClick={function () {
                                    carousel(prodId);
                                  }}
                                />
                                <img
                                  className="carousel__button--right"
                                  id="carousel__button--right"
                                  src={Right}
                                  alt={"img"}
                                  onClick={function () {
                                    carousel(prodId);
                                  }}
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                        <p className="result-card__rating">
                          Average Rating: {rating.toFixed(1)}
                        </p>
                        <h3 className="result-card__brand">{brand}</h3>
                        <p className="result-card__title">{product.title}</p>
                        <div className="result-card__prices">
                          <p className="result-card__old-price">
                            {product.variants[0].priceInfo.price !==
                            product.variants[0].priceInfo.originalPrice
                              ? "$" +
                                parseFloat(
                                  product.variants[0].priceInfo.originalPrice
                                ).toFixed(2)
                              : null}
                          </p>
                          <p className="result-card__price">
                            {"$" + product.variants[0].priceInfo.price}
                          </p>
                        </div>
                      </div>
                      <div>
                        <button className="result-card__button">
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </>
    );
  }
}
